import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addApiRegistration',
  components: {},
  props: ['apiRegistrationRowDetail'],
  data() {
    return {
      showValueSetModal: false,
      loader: false,
      unsubscribe: null,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      hdrId:0,
      gstMasterId: 0,
      apiName: null,
      apiType: {
        value: null,
        text: null
      },
      apiUrl: null,
      callingType: null,
      callingObjectDtl: null,
      sampleRequestJson: null,
      sampleResponseJson: null,
      userName: null,
      password: null,
      aliasName: null,
      resPath: null,
      reqPath: null,
      token: null
    }
  },
  mounted() {
    if (this.apiRegistrationRowDetail) {
      this.fillRecord(this.apiRegistrationRowDetail);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditApiRegistration();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    fillRecord() {
      this.apiName = this.apiRegistrationRowDetail.api_name;
      this.apiUrl = this.apiRegistrationRowDetail.api_url;
      this.apiType.text = this.apiRegistrationRowDetail.api_type;
      this.callingType = this.apiRegistrationRowDetail.calling_type;
      this.callingObjectDtl = this.apiRegistrationRowDetail.calling_object_dtl;
      this.sampleRequestJson = this.apiRegistrationRowDetail.sample_request_json;
      this.sampleResponseJson = this.apiRegistrationRowDetail.sample_response_json;
      this.userName = this.apiRegistrationRowDetail.user_name;
      this.password = this.apiRegistrationRowDetail.password;
      this.aliasName = this.apiRegistrationRowDetail.alias_name;
      this.reqPath = this.apiRegistrationRowDetail.request_file_path;
      this.resPath = this.apiRegistrationRowDetail.response_file_path;
      this.token = this.apiRegistrationRowDetail.token;

    },
    addEditApiRegistration() {
      const payload = {
        hdr_id: this.hdrId,
        api_name: this.apiName,
        api_url: this.apiUrl,
        api_type: this.apiType.value,
        calling_type: this.callingType,
        calling_object_dtl: this.callingObjectDtl,
        sample_request_json: this.sampleRequestJson,
        sample_response_json: this.sampleResponseJson,
        user_name: this.userName,
        password: this.password,
        token: this.token,
        alias_name: this.aliasName,
        request_file_path: this.reqPath,
        response_file_path: this.resPath
      };
      this.loader = true;
      this.$store
        .dispatch('apiAdmin/addEditApiRegistrationDetail', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode) {   // To Open Value Set Modal
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {   // To Close Value Set Modal
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {   // To Selected Value from value set modal
      if (this.vsetCode === 'API_TYPE') {
        this.apiType.text = item.value_meaning,
          this.apiType.value = item.value_code
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};