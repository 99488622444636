import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import addApiRegistration from '../apiRegistration/addApiRegistration';
export default {
    name: 'apiRegistration',
    watch: {
        currentPage: function() {
          this.getApiRegistrationGrid();
        },
        perPage: function() {
          this.currentPage = 1;
          this.getApiRegistrationGrid();
        }
      },
    components: { DatePicker, addApiRegistration },
    data() {
        return {
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            totalRows: null,
            loader: false, showValueSetModal: false,
            editMode: false,
            showAlert: false,
            isSuccess: false,
            responseMsg: '',
            apiRegistrationRowDetail: null,
            showApiRegistrationModal: false,
            apiName: {
                value: null,
                text: null
            },
            apiType: {
                value: null,
                text: null
            },
            apiRegistrationData: [],
            apiRegistrationFields: [
                {
                    key: 'password'
                },
                {
                    key: 'calling_type'
                },
                {
                    key: 'api_name',
                    label: 'API Name'
                },
                {
                    key: 'user_name'
                },
                {
                    key: 'api_url',
                    label: 'API Url'
                },
                {
                    key: 'api_type',
                    label: 'API Type'
                },
                {
                    key: 'calling_object_dtl'
                },
                {
                    key: 'sample_request_json'
                },
                {
                    key: 'sample_response_json'
                },
                {
                    key: 'alias_name'
                },
                {
                    key: 'request_file_path'
                },
                {
                    key: 'response_file_path'
                },
                {
                    key: 'token'
                }
            ]
        };
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'add') {
                    this.showApiRegistrationModal = true;
                    this.apiRegistrationRowDetail=null;
                }
            }
        });
    },
    methods: {
        getApiRegistrationGrid() {    // To Get API Registration Grid Data
            const payload = {
                api_type: this.apiType.value
            }
            this.loader = true;
            this.$store
                .dispatch('apiAdmin/getApiRegistrationGridList', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.apiRegistrationData = response.data.data.page;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        showHideApiRegistrationModal(flag) {
            this.showApiRegistrationModal = flag;
        },
        rowSelected(item) {
            this.apiRegistrationRowDetail = item;     // To Props
            this.showApiRegistrationModal = true;    // To Open Child Modal
        },
        openValueSetModal(vsetCode) {   // To Open Value Set Modal
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        closeValueSetModal() {   // To Close Value Set Modal
            this.showValueSetModal = false;
        },
        selectedvalueSet(item) {   // To Selected Value from value set modal
            if (this.vsetCode === 'API_TYPE') {
                this.apiType.text = item.value_meaning,
                    this.apiType.value = item.value_code
            }
        },
        clearFilters() { // To Clear Filters
            this.apiType.text = null;
            this.apiType.value = null;
            this.apiName.text = null;
            this.apiName.value = null;
            this.apiRegistrationData=[];
        }
    },
    beforeDestroy() {
        this.unsubscribe();
        clearTimeout(this.setTimeVsetCode);
    }
};